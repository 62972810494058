<template>
  <div class="download-box">
    <div class="head">
      <div class="login_h_con" @click="goPage('/')"></div>
      <p class="l_h_txt">{{$t('common.header.ylkjhyn')}}</p>
    </div>
    <div class="download_con">
      <div class="down_left">
        <div class="down_phone"></div>
      </div>
      <div class="down_right">
        <p class="d_right_icon"></p>
        <div class="d_r_con">
          <div class="d_d_box">
            <p class="b_single">
              <span class="b_s_icon b_s_ios"></span>
              <span>iOS</span>
            </p>
            <div class="code_box">
              <div class="code_con code_ios"></div>
            </div>
            <p class="code-tit">{{$t('login.downloadApp.smxz')}}</p>
          </div>
          <div class="d_d_box">
            <p class="b_single">
              <span class="b_s_icon b_s_and"></span>
              <span>Android</span>
            </p>
            <div class="code_box">
              <div class="code_con code_android"></div>
            </div>
            <p class="code-tit">{{$t('login.downloadApp.smxz')}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    created() {
      document.body.style.height = '100%';
    },
    methods: {
      goPage(url) {
        if(url) {
          this.$router.push(url);
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .download-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #F7F7F7;
    .head {
      height: 1rem;
      padding: 0.23rem 3.6rem 0.23rem;
      box-sizing: border-box;
      background-color: #fff;
      display: flex;
      align-items: center;
      border-bottom: 0.01rem solid #E0E0E0;
      .login_h_con {
        width: 2.06rem;
        height: 0.52rem;
        margin-right: 0.33rem;
        background-size: 100% 100%;
        background-image: url("/static/images/logoB.png");
      }
      .login_box {
        width: 2.08rem;
        height: 0.54rem;
        margin-right: 0.31rem;
      }
      .l_h_txt {
        color: #666;
        font-size: 0.16rem;
        line-height: 0.34rem;
      }
    }
    .download_con{
      flex: 1;
      display: flex;
      align-items: center;
      padding-top: 1.2rem;
      box-sizing: border-box;
      background-size: 100% 100%;
      background-image: url("/static/images/web_doc_download.png");
    }
    .down_left{
      width: 48%;
      display: flex;
      justify-content: flex-end;
      .down_phone{
        width: 4.75rem;
        height: 5.48rem;
        background-size: 100% 100%;
        background-image: url("/static/images/web_doc_download_phone.png");
      }
    }
    .down_right{
      flex: 1;
      padding-right: 3.32rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .d_right_icon{
        width: 2.02rem;
        height: 0.52rem;
        margin-bottom: 1rem;
        background-size: 100% 100%;
        background-image: url("/static/images/logo_white.png");
      }
      .d_r_con{
        display: flex;
        align-items: center;
        .d_d_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          &:first-child {
            margin-right: 0.37rem;
          }
          .b_single{
            width: 1.7rem;
            height: 0.4rem;
            margin-bottom: 0.35rem;
            padding-left: 0.3rem;
            border-radius: 0.4rem;
            background-color: #9E5FD1;
            border: 0.01rem solid #CD98F7;
            color: #fff;
            font-size: 0.2rem;
            display: flex;
            align-items: center;
            .b_s_icon {
              display: inline-block;
              width: 0.24rem;
              height: 0.24rem;
              background-size: 100% 100%;
              margin-right: 0.12rem;
              margin-bottom: 0.02rem;
            }
            .b_s_and{
              background-image: url("/static/images/download_Android.png");
            }
            .b_s_ios{
              background-image: url("/static/images/download_ios.png");
            }
          }
          .code_box{
            width: 1.32rem;
            height: 1.32rem;
            border-radius: 6px;
            background-color: #fff;
            padding: 0.06rem;
            box-sizing: border-box;
            margin-bottom: 0.1rem;
            .code_con{
              width: 100%;
              height: 100%;
              background-size: 100% 100%;
            }
            .code_ios{
              background-image: url("/static/images/home/ios.png");
            }
            .code_android{
              background-image: url("/static/images/home/android.png");
            }
          }
          .code-tit{
            color: #fff;
            font-size: 0.14rem;
          }
        }
      }
    }
  }
</style>
